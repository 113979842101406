import * as React from 'react';
import styled from 'styled-components';

// tweet assets
import SamneetChepalProfilePicture from '@/assets/images/tweets/samneet-chepal-profile-picture.jpg';
import SamneetChepalTweetPhoto from '@/assets/images/tweets/samneet-chepal-tweet-photo.jpg';
import DylanLeClairProfilePicture from '@/assets/images/tweets/DylanLeClair_profile-picture.jpg';
import DylanLeClairTweetPhoto from '@/assets/images/tweets/DylanLeClair_tweet-photo.jpg';
import DylanLeClairTweetPhotoOct242023 from '@/assets/images/tweets/DylanLeClair_tweet-photo-oct-24-2023.jpg';
import DylanLeClairTweetPhotoAug042024 from '@/assets/images/tweets/DylanLeClair_tweet-photo-aug-04-2024.jpg';
import AlexKrugerProfilePicture from '@/assets/images/tweets/AlexKruger-profile-picture.jpg';
import AlexKrugerTweetPhoto from '@/assets/images/tweets/AlexKruger-tweet-photo.jpg';
import TurbanetherProfilePicture from '@/assets/images/tweets/turbanether-profile-picture.jpg';
import TurbanetherTweetPhoto from '@/assets/images/tweets/turbanether-tweet-photo.jpg';
import { Title } from '@/styles/global-styled-components';
import { TextSection } from '@/components/Base/TextSection';

const TwitterLink = styled.span`
  color: rgb(29, 155, 240);
  font-weight: 600;
`;

type TwitterCardProps = {
  children: React.ReactNode;
  likes: number;
  date: Date;
  link: string;
  profileImg: string;
  displayName: string;
  handle: string;
};

const TwitterCard = ({
  children,
  likes,
  link,
  profileImg,
  displayName,
  handle,
  date,
}: TwitterCardProps) => {
  const timeText = date.toLocaleTimeString([], {
    hour: `2-digit`,
    minute: `2-digit`,
    hour12: true,
  });
  const dateText = date.toLocaleDateString(`en-US`, {
    month: `short`,
    day: `numeric`,
    year: `numeric`,
  });
  return (
    <div className="h-full rounded-lg border border-gray-500 border-opacity-20 bg-white p-5 hover:shadow">
      <a href={link} target="_blank" rel="noopener noreferrer" className="">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <img className="h-11 w-11 rounded-full" src={profileImg} alt="" />
            <div className="ml-1.5 text-sm leading-tight">
              <span className="block font-bold text-black">{displayName}</span>
              <span className="block font-normal text-gray-500">{handle}</span>
            </div>
          </div>
          <svg
            className="inline-block h-6 w-auto text-blue-400"
            viewBox="0 0 512 512"
          >
            <g>
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
            </g>
          </svg>
        </div>
        <p className="mt-3 block leading-snug text-black">{children}</p>
        <p className="my-0.5 flex items-center py-1 text-base text-gray-500">
          <svg
            viewBox="0 0 24 24"
            className="mr-1 h-3.5 w-4 fill-current r-1re7ezh r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
          >
            <g>
              <path d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z" />
            </g>
          </svg>
          {likes} · {timeText} · {dateText}
        </p>
      </a>
    </div>
  );
};

const testimonials = [
  {
    body: (
      <>
        <TwitterLink>$BTC</TwitterLink> vol finally woke up.{` `}
        <TwitterLink>$BVIV</TwitterLink>
        <br />
        <img
          className="rounded-sm py-4"
          src={DylanLeClairTweetPhotoAug042024}
          alt=""
        />
      </>
    ),
    likes: `718`,
    date: new Date(1722839640000),
    link: `https://x.com/DylanLeClair_/status/1820347602174349556`,
    profileImg: DylanLeClairProfilePicture,
    displayName: `Dylan LeClair 🟠`,
    handle: `@DylanLeClair_`,
  },
  {
    body: (
      <>
        A rollercoaster ride indeed – let&apos;s consider those bravely
        clutching long vol positions after that fleeting surge in vol last week.
        Vol buyers can&apos;t catch a break!
        <TwitterLink>@volmexfinance</TwitterLink>
        &apos;s BVIV currently trading just 5 points above all-time lows with
        front-end vols continuing to tumble.
        <img className="rounded-sm py-4" src={SamneetChepalTweetPhoto} alt="" />
      </>
    ),
    likes: 13,
    date: new Date(1692309840000),
    link: `https://twitter.com/samchepal/status/1695306968162795979`,
    profileImg: SamneetChepalProfilePicture,
    displayName: `Samneet Chepal`,
    handle: `@samchepal`,
  },
  {
    body: (
      <>
        Holy **** <br />
        <br />
        <TwitterLink>$BTC</TwitterLink> implied vol just keeps bidding...
        <br />
        <br />
        Whomever was/is short calls is absolutely getting their faced ripped
        off.
        <img
          className="rounded-sm py-4"
          src={DylanLeClairTweetPhotoOct242023}
          alt=""
        />
      </>
    ),
    likes: `1.3k`,
    date: new Date(1698154860000),
    link: `https://twitter.com/DylanLeClair_/status/1716812167868194878`,
    profileImg: DylanLeClairProfilePicture,
    displayName: `Dylan LeClair 🟠`,
    handle: `@DylanLeClair_`,
  },
  {
    body: (
      <>
        <TwitterLink>$BTC</TwitterLink> implied vol back to the 50s (shoutout to
        <TwitterLink>@volmexfinance</TwitterLink>, creators of the
        <TwitterLink>$BVIV</TwitterLink> implied vol index)
        <img className="rounded-sm py-4" src={AlexKrugerTweetPhoto} alt="" />
      </>
    ),
    likes: 89,
    date: new Date(1692315120000),
    link: `https://twitter.com/krugermacro/status/1692318562109800458`,
    profileImg: AlexKrugerProfilePicture,
    displayName: `Alex Krüger`,
    handle: `@krugermacro`,
  },
  {
    body: (
      <>
        <TwitterLink>$BVIV</TwitterLink> and <TwitterLink>$EVIV</TwitterLink>
        rallied &gt;5-6 points in response. It&apos;s almost like folks read the
        <TwitterLink>#FED</TwitterLink>&apos;s tone as
        <TwitterLink>#hawkish</TwitterLink> or something, in that even though
        they&apos;ve paused hikes, the end of the year is still 6m+ away...😅
        <TwitterLink>#VIX</TwitterLink> back to the ~13.8 levels we saw on last
        Fridays&apos; close.
        <img className="rounded-sm py-4" src={TurbanetherTweetPhoto} alt="" />
        {/* <GatsbyImage image={getImage(SamneetChepalTweetPhoto)} /> */}
      </>
    ),
    likes: 2,
    date: new Date(1686777180000),
    link: `https://twitter.com/turbanether/status/1669090596277870592`,
    profileImg: TurbanetherProfilePicture,
    displayName: `turbanether.eth`,
    handle: `@turbanether`,
  },
  {
    body: (
      <>
        <TwitterLink>$BTC</TwitterLink> VOLATILITY IS BACK 🚨🚨
        <img className="rounded-sm py-4" src={DylanLeClairTweetPhoto} alt="" />
      </>
    ),
    likes: 13,
    date: new Date(1693002420000),
    link: `https://twitter.com/dylanleclair_/status/1692296288681730337`,
    profileImg: DylanLeClairProfilePicture,
    displayName: `Dylan LeClair 🟠`,
    handle: `@DylanLeClair_`,
  },
];

const TestimonialsSection: React.FC = () => (
  <div className="bg-white w-full">
    <div className="page-padding pb-24 lg:pb-28 py-20 lg:grid grid-cols-2">
      <div className="flex flex-col gap-4 pr-20 justify-center">
        <Title>See how others are using Volmex Indices</Title>
        <TextSection
          text="Use the EVIV and BVIV Indices to your advantage in your trading, investing, and analysis."
          className="w-full whitespace-normal sm:whitespace-nowrap lg:whitespace-normal"
        />
      </div>
      <div className="flex flex-row gap-6 flex-shrink-0 overflow-x-scroll hide-scroll">
        {testimonials.map((testimonial, i) => (
          <div className="min-w-[320px] sm:min-w-[400px]" key={testimonial.handle + String(i)}>
            <TwitterCard
              likes={Number(testimonial.likes)}
              date={testimonial.date}
              link={testimonial.link}
              profileImg={testimonial.profileImg}
              displayName={testimonial.displayName}
              handle={testimonial.handle}
            >
              {testimonial.body}
            </TwitterCard>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default TestimonialsSection;
