import React from 'react';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import Main from '@/components/Home/Main';
import About from '@/components/Home/About';
// import Tvl from '@/components/Home/Tvl';
import UseCases from '@/components/Home/UseCases';
import Community from '@/components/Home/Community';
import Backers from '@/components/Home/Backers';
import Blurbs from '@/components/Home/Blurbs';
import Timeseries from '@/components/Home/Timeseries';
import FAQ from '@/components/Home/FAQ';
import Products from '@/components/Home/Products';
import Tradingview from '@/components/Home/Tradingview';
import Partners from '@/components/Home/Partners';
import Stats from '@/components/Home/Stats';
import TestimonialsSection from '@/components/Home/TwitterCard';
import Research from '@/components/Home/Research';
import Bitfinex from '@/components/Home/Bitfinex';

const Home = () => (
  <Layout>
    <SEO />
    <Main />
    <Timeseries />
    <Products />
    <About />
    {/* <Tvl /> */}
    {/* <Stats /> */}
    <UseCases />
    <Blurbs />
    <Community />
    <Bitfinex />
    <Tradingview />
    <Partners />
    <Backers />
    <TestimonialsSection />
    <Research />
    <FAQ />
  </Layout>
);

export default Home;
