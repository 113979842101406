import { useBitfinexTotalNotional } from '@/hooks/useBitfinexTotalNotional';
import { formatNumber } from '@/utils/FormattingUtils';
import * as React from 'react';

import styled from 'styled-components';
import Carousel from './Carousel';

const Value = styled.div`
  letter-spacing: -0.073em;
  font-size: 70px;
  //   @media (max-width: ${(props) => `${props.theme.screens.xl}`}) {
  //     font-size: 50px;
  //   }
  @media (max-width: ${(props) => `${props.theme.screens.md}`}) {
    font-size: 50px;
    padding-top: 1rem;
  }
  color: ${(props) => `${props.theme.colors.white}`};
`;

const PerpetualsTraded = () => {
  const totalNotional = useBitfinexTotalNotional();
  if (!totalNotional) return <Carousel />;
  return (
    <div>
      {/* <Title>Total value locked</Title> */}
      <div className="whitespace-nowrap justify-start flex text-white max-w-xs sm:text-lg absolute z-[100]">
        Total Perpetuals Traded
      </div>
      <Value className="text-left pb-4 pt-6  text-[35px] sm:text-[70px]">
        ${formatNumber(totalNotional)}
      </Value>
    </div>
  );
};

export default PerpetualsTraded;
