import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Asset,
  LookbackPeriod,
  TimeBucket,
  TimeSeries,
} from '@/types/TimeSeries';
import { useTimeSeriesQuery } from '@/hooks/useTimeSeriesQuery';
import { BeatLoader } from 'react-spinners';
import Chart from '@/components/Home/Main/Chart';
import Variation from '@/components/Home/Timeseries/Variation';
import Controls from '@/components/Home/Timeseries/Controls';
import TodayInfo from '@/components/Home/Timeseries/TodayInfo';
import { getLookbackPeriod } from '@/utils/TimeSeriesUtils';

const ChartSection = () => {
  const [asset, setAsset] = useState<Asset>(Asset.BTC);
  const [timeBucket, setTimeBucket] = useState<TimeBucket>(TimeBucket[`1D`]);
  const [lookbackPeriod, setLookbackPeriod] = useState<LookbackPeriod>(
    getLookbackPeriod(timeBucket),
  );
  const [todayValues, setTodayValues] = useState<Map<Asset, TimeSeries>>(
    new Map(),
  );
  const [yesterdayValues, setYesterdayValues] = useState<
    Map<Asset, TimeSeries>
  >(new Map());

  // grab the data to populate variation and today info separately,
  // since the main query might be set for a time bucket different from 1D when first changing the asset
  const { timeSeries: btcInitialTimeSeries } = useTimeSeriesQuery(
    "BTC",
    TimeBucket[`1D`],
    LookbackPeriod[`1D`],
  );



  useEffect(() => {
    if (btcInitialTimeSeries && btcInitialTimeSeries.length > 1) {
      if (!todayValues.get(Asset.BTC)) {
        setTodayValues(todayValues.set(Asset.BTC, btcInitialTimeSeries.at(-1)));
        setYesterdayValues(
          yesterdayValues.set(Asset.BTC, btcInitialTimeSeries.at(-2)),
        );
      }
    }
  }, [btcInitialTimeSeries]);

  useEffect(() => {
    setLookbackPeriod(getLookbackPeriod(timeBucket));
  }, [timeBucket]);

  const { loading, timeSeries } = useTimeSeriesQuery(
    asset,
    timeBucket,
    lookbackPeriod,
  );

  return (
    <>
      {loading && (
        <BeatLoader className="!flex items-center justify-center h-full" />
      )}
      {!loading && (
        <Chart timeSeries={timeSeries} />
      )}
    </>
  );
};

export default ChartSection;
