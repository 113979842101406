import { REST_API_URL } from '@/constants';
import { LookbackPeriod, TimeBucket } from '@/types/TimeSeries';
import { useQuery as useTanQuery } from '@tanstack/react-query';
import axios from 'axios';

const timeBucketToResolution = {
  [TimeBucket[`1m`]]: `1`,
  [TimeBucket[`1D`]]: `D`,
  [TimeBucket[`1W`]]: `D`,
  [TimeBucket[`1M`]]: `D`,
};

const getStartTimestamp = (lookbackPeriod: LookbackPeriod) => {
  const lookbackPeriodInDays = LookbackPeriod[lookbackPeriod];
  const currentDate = new Date();
  const startDate = new Date();
  if (lookbackPeriodInDays === `1m`) {
    startDate.setTime(currentDate.getTime() - 1000 * 60 * 2); // 2m lookback to improve BANNER and HERO value load time
  } else if (lookbackPeriodInDays === `1D`) {
    startDate.setDate(currentDate.getDate() - 1);
  } else if (lookbackPeriodInDays === `60D`) {
    startDate.setDate(currentDate.getDate() - 60);
  } else if (lookbackPeriodInDays === `150D`) {
    startDate.setDate(currentDate.getDate() - 150);
  } else if (lookbackPeriodInDays === `365D`) {
    startDate.setDate(currentDate.getDate() - 365);
  }
  return Math.floor(startDate.getTime() / 1000);
};

export const useTimeSeriesQuery = (
  asset: 'ETH' | 'BTC',
  timeBucket: TimeBucket,
  lookbackPeriod: LookbackPeriod,
) => {
  const timeBucketInDays = TimeBucket[timeBucket]; // this is necessary because for now the API only works with days as timeBucket
  const resolution = timeBucketToResolution[timeBucketInDays];

  const currentDate = Math.floor(new Date().getTime() / 1000);
  const startDate = getStartTimestamp(lookbackPeriod);

  const { data: assetData, isLoading: loading } = useTanQuery(
    [`timeseries query`, asset, timeBucket, lookbackPeriod],
    () => {
      const url = new URL(`${REST_API_URL}/public/iv/history`);
      url.searchParams.append(`symbol`, asset === `ETH` ? `EVIV` : `BVIV`);
      url.searchParams.append(`resolution`, resolution);
      url.searchParams.append(`from`, String(startDate));
      url.searchParams.append(`to`, String(currentDate));
      return axios.get(url.toString());
    },
  );

  return {
    timeSeries: assetData?.data?.t.map((d, i) => ({
      date: new Date(d * 1000).toISOString(),
      open: assetData?.data?.o[i],
      high: assetData?.data?.h[i],
      low: assetData?.data?.l[i],
      close: assetData?.data?.c[i],
    })),
    loading,
  };
};
