export enum Asset {
  ETH = `ETH`,
  BTC = `BTC`,
}

export enum TimeBucket {
  '1m' = `1m`,
  '1D' = `1D`,
  '1W' = `7D`,
  '1M' = `30D`,
}

export enum LookbackPeriod {
  '1m' = `1m`,
  '1D' = `1D`,
  '60D' = `60D`,
  '150D' = `150D`,
  '365D' = `365D`,
}

export const TimeBucketLabels = [`1D`, `1W`, `1M`];

export type TimeSeries = {
  open: number;
  close: number;
  low: number;
  high: number;
  date: Date;
};
