import * as React from 'react';
import { FunctionComponent, useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Heading } from '@/styles/global-styled-components';
import { BeatLoader } from 'react-spinners';
import { IndexDataContext } from '@/context/IndexData';
import PerpetualsTraded from './PerpetualsTraded';
import { STREAMING_ASSETS } from '@/constants';

interface AssetProps {
  label: string;
  value: number;
  hasIncreased?: boolean;
}

const AssetIndex: FunctionComponent<AssetProps> = ({
  label,
  value,
  hasIncreased,
}) => (
  <Heading className="flex font-black text-[30px] md:text-[50px] flex-nowrap">
    <span>{label}</span>
    <span className="text-sm pl-2 pt-1 font-thin">®</span>
    <span
      className={classNames(`ml-4 md:ml-8`, {
        'text-green-200': hasIncreased,
        'text-pink': !hasIncreased,
      })}
    >
      {hasIncreased ? `↑` : `↓`} {value}
    </span>
  </Heading>
);

const VivIndex = () => {
  const { prices } = useContext(IndexDataContext);
  
  const [currentPrices, setCurrentPrices] = useState(prices)
  const [hasIncreased, setHasIncreased] = useState(STREAMING_ASSETS.reduce((acc, asset) => ({ ...acc, [asset]: false }), {}))

  useEffect(() => {
    const tempHasIncreased = structuredClone(hasIncreased)
    Object.keys(prices).map((key) => {
      if ( Number(currentPrices[key]) && Number(prices[key]) > Number(currentPrices[key]) ) {
        tempHasIncreased[key] = true
      } else tempHasIncreased[key] = false
    })
    setHasIncreased(tempHasIncreased)
    setCurrentPrices(prices)
  }, [prices]);

  return (
    <div className="flex flex-col border-l-4 pb-8 pl-8 mt-8">
      <div className="text-xl mb-2">Volmex Implied Volatility Indices</div>
      <AssetIndex
        label="BVIV"
        value={prices["BVIV"]}
        hasIncreased={hasIncreased["BVIV"]}
      />
      <AssetIndex
        label="EVIV"
        value={prices["EVIV"]}
        hasIncreased={hasIncreased["EVIV"]}
      />
      <div className="block lg:hidden -mb-8 mt-8">
        <PerpetualsTraded />
      </div>
    </div>
  );
};

export default VivIndex;
