import * as React from 'react';
import { FC } from 'react';

import { TimeSeries } from '@/types/TimeSeries';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate, formatNumber } from '@/utils/FormattingUtils';

interface ChartProps {
  timeSeries: TimeSeries[];
}

const CustomTooltip = ({ active, payload }: any) => {
  const formattedValue = formatNumber(payload?.[0]?.value);
  const formattedDate = formatDate(new Date(payload?.[0]?.payload.date));

  if (active && payload && payload.length) {
    return (
      <div className="px-5 py-2 bg-black flex w-64 items-center justify-between">
        <div className="text-base w-1/2">{formattedDate}</div>
        <div className="text-4xl w-1/2 text-right">{formattedValue}</div>
      </div>
    );
  }

  return null;
};

const Chart: FC<ChartProps> = ({ timeSeries }) => (
  <ResponsiveContainer height={208}>
    <AreaChart
      data={timeSeries}
      margin={{ top: 10, left: 10, bottom: 5, right: 10 }}
    >
      <defs>
        <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="20%" stopColor="#a0a0a0" stopOpacity={0.3} />
          <stop offset="80%" stopColor="#c4c4c4" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="axisLineGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="20%" stopColor="#a0a0a0" stopOpacity={0.5} />
          <stop offset="80%" stopColor="#00FFB2" stopOpacity={0.5} />
        </linearGradient>
      </defs>
      <Line
        type="monotone"
        dataKey="close"
        stroke="#8884d8"
        dot={{ fill: `black` }}
      />
      <XAxis hide />
      <YAxis
        type="number"
        mirror
        allowDecimals
        tickCount={3}
        tickLine={false}
        tickMargin={-4}
        padding={{ top: 5, bottom: 5 }}
        scale="linear"
        orientation="right"
        domain={[`auto`, `auto`]}
        tickFormatter={formatNumber}
        axisLine={{ stroke: `#B8C9C8`, strokeWidth: 0.3 }}
        style={{
          fontSize: `0.7rem`,
        }}
      />
      <CartesianGrid
        horizontal={false}
        stroke="#dae0e0"
        strokeWidth={0.4}
        verticalPoints={[10, 190, 370, 550, 730, 910, 1090]} // sets the vertical lines, based on the px coordinates of x-axis
      />
      <Tooltip
        content={<CustomTooltip />}
        wrapperStyle={{ border: 0, outline: `none` }}
      />
      <Area
        type="monotone"
        dataKey="close"
        stroke="black"
        fillOpacity={0}
        fill="url(#areaGradient)"
        activeDot={{
          fill: `black`,
        }}
      />
    </AreaChart>
  </ResponsiveContainer>
);

export default Chart;
